import React from "react";




class LeftHandNavigation extends React.Component {

	constructor(props){
		super()
	}

 

    render() {
      return (
    
      <nav id="left-hand-navigation">
        <h2><a href={this.props.leftHandNavigationData.nav[0].pageURL}>{this.props.leftHandNavigationData.nav[0].pageName}</a></h2>	
	    <ul>

          {this.props.leftHandNavigationData.nav[0].pages.map((level2, index) => 
            <li key={index}><span className={level2.class}><a href={level2.pageURL}>{level2.pageName}</a></span>
            <ul>
              {level2.pages.map((level3, index) => 
                <li key={index}><span className={level3.class}><a href={level3.pageURL}>{level3.pageName}</a></span>
	              <ul>
	              {level3.pages.map((level4, index) => 
	                <li key={index}><span className={level4.class}><a href={level4.pageURL}>{level4.pageName}</a></span></li>
	               )} 
	              </ul>
                </li>
               )}   	      	      	      	      
	        </ul>


            </li>
          )} 

	           	      	      	      	      
	    </ul>
      </nav>
    );
  }
}

export default LeftHandNavigation;
