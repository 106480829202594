import React from "react";
import QuickLinks from "../quick-links/QuickLinks";
// import QuickLinksDataLocal from "../../data-files/quicklinks-data.json";
import QuickLinksDataTopicPageLocal from "../../data-files/quicklinks-level2-data.json";
import OverlayAnimalWelfareSVG from "./OverlayAnimalWelfareSVG";
// import AutoComplete from "./auto-complete/AutoComplete";

// Global functions
import { usingLocalData } from "../../functions";

// var QuickLinksData;
var QuickLinksTopicPageData;

if (usingLocalData()) {
  // QuickLinksData = QuickLinksDataLocal;
  QuickLinksTopicPageData = QuickLinksDataTopicPageLocal;
} else {
  // if (typeof QuickLinksDataCMS != "undefined") {
  //   // QuickLinksData = QuickLinksDataCMS;
  // }
  if (typeof QuickLinksDataTopicPageCMS != "undefined") {
    QuickLinksTopicPageData = QuickLinksDataTopicPageCMS;
  }
}

class HeroBannerAnimalWelfare extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div
        className={"hero-banner-container " + this.props.HeroBanner.pageType}
      >
        {(this.props.HeroBanner.pageType == "content" ||
          this.props.HeroBanner.pageType == "subtopic") && (
          <section
            id="hero-banner"
            className={"hero-banner " + this.props.HeroBanner.pageType}
          ></section>
        )}

        {(this.props.HeroBanner.pageType == "section" ||
          this.props.HeroBanner.pageType == "topic") && (
          <section
            id="hero-banner"
            className={"hero-banner " + this.props.HeroBanner.pageType}
          >
            <div className="container-fluid">
              <div className="row middle-xs">
                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 pr-0 order-lg-2">
                  <div className="banner__image-wrapper">
                    {(this.props.HeroBanner.pageType == "section" ||
                      this.props.HeroBanner.pageType == "topic") && (
                      <OverlayAnimalWelfareSVG />
                    )}

                    <div
                      className={
                        "banner__image " + this.props.HeroBanner.pageType
                      }
                      alt={this.props.HeroBanner.bannerAlt}
                      style={{
                        backgroundImage: `url(${this.props.HeroBanner.banner})`
                      }}
                    ></div>
                  </div>
                </div>

                <div className="heading-container col-sm-12 col-md-12 col-lg-5 col-xl-4 order-lg-1">
                  <div className="banner__text-wrapper">
                    {(this.props.HeroBanner.pageType == "section" ||
                      this.props.HeroBanner.pageType == "topic") && (
                      <div>
                        <h1 className="banner__heading">
                          {this.props.HeroBanner.heading}
                        </h1>
                        <p className="banner-subheading">
                          {this.props.HeroBanner.subheading}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {(this.props.HeroBanner.pageType == "section" ||
                this.props.HeroBanner.pageType == "topic") && (
                <div className="QuickLinks-container col-sm-12">
                  <QuickLinks QuickLinks={QuickLinksTopicPageData.pages} />
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default HeroBannerAnimalWelfare;
