import React from "react";

class TraceabilityDirectorySearch extends React.Component {
  constructor (props) {
    super();

    this.resetFilter = this.resetFilter.bind(this);
    this.getQueryParam = this.getQueryParam.bind(this);
  }
  
  resetFilter () {
    window.location.href = window.location.origin + window.location.pathname;
  }
  
  getQueryParam (param) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    
    return params[param] && params[param].length > 0 ? params[param] : '';
  }

  render() {
    const { submissionURL } = this.props.data;
    const defaultNameQuery = this.getQueryParam('queries_traceability.user.name_query');
    const defaultSupplyChainQuery = this.getQueryParam('queries_traceability.user.supplychain_query');

    return (
      <form id="search_page_922582" method="get" action={submissionURL}>
        <div className="traceability-directory-search-filter">
          <h3>Filter</h3>
          {/* <input type="hidden" name="gitbridge" value="904666" className="sq-form-field" id="gitbridge" /> */}
          <label htmlFor="queries_traceability.user.name_query">Name</label>
          <input type="text" name="queries_traceability.user.name_query" defaultValue={defaultNameQuery} className="sq-form-field" id="queries_traceability.user.name_query" />
          <input type="hidden" name="queries_traceability.user.supplychain_query_posted" value="1" className="sq-form-field" id="queries_traceability.user.supplychain_query_posted" />
          <label htmlFor="queries_traceability.user.supplychain_query">Supply chain</label>
          <select name="queries_traceability.user.supplychain_query"
            className="sq-form-field"
            id="queries_traceability.user.supplychain_query"
            defaultValue={defaultSupplyChainQuery}
          >
            <option value="">Please select an option</option>
            <option value="farm">Farm</option>
            <option value="transport-and-logistics">Transport and logistics</option>
            <option value="service-provider">Service provider</option>
            <option value="food-transformation">Food transformation</option>
            <option value="distribution">Distribution</option>
            <option value="wholesaler">Wholesaler</option>
            <option value="retailer">Retailer</option>
            <option value="government">Government (State and Federal)</option>
            <option value="academia">Higher education</option>
            <option value="consumer">Consumer</option>
            <option value="other">Other</option>
          </select>
          <input type="reset" name="search_page_922582_reset_button" value="Reset" className="sq-form-field" id="search_page_922582_reset_button" onClick={() => { this.resetFilter() }}></input>
          <input type="text" name="search_page_922582_extra_field" id="search_page_922582_extra_field" disabled="disabled" title="Hidden Honeypot Field" className="d-none" />
        </div>
        <input type="submit" name="search_page_922582_submit_button" value="Search" className="button-black2" />
        <div>
          <input type="hidden" name="current_result_page" value="1" />
          <input type="hidden" name="results_per_page" value="100" />
          <input type="hidden" name="submitted_search_category" value="" />
          <input type="hidden" name="mode" value="" />
        </div>
      </form>
    );
  }
}

export default TraceabilityDirectorySearch;