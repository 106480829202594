import React from "react";
import { getCmsData } from "../../functions";
import SocialIconsGroup from "../social-icons-group/SocialIconsGroup";

const Breadcrumbs = (props) => {
  const socialLinksData = getCmsData("SocialLinksDataCMS");
  if (!socialLinksData) return null;

  const breadcrumbsData = getCmsData("breadcrumbsDataCMS");
  if (!breadcrumbsData) return null;
  const breadcrumbs = breadcrumbsData.links;

  const breadcrumbStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs.map((item, index, arr) => {
      if (index == arr.length - 1) {
        return {
          "@type": "ListItem",
          position: index + 1,
          name: item.pageName,
        };
      } else {
        return {
          "@type": "ListItem",
          position: index + 1,
          name: item.pageName,
          item: item.pageURL,
        };
      }
    }),
  };
  //   const { results } = cmsData;

  return (
    <div className="breadcrumbs-social-container container-fluid">
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbStructuredData)}
      </script>
      <div className="row">
        <div className="col-12 col-sm-8 ">
          <nav id="breadcrumbs">
            <ul>
              {breadcrumbs.map((item, index, arr) => {
                {
                  if (index == arr.length - 1) {
                    return <li key={index}>{item.pageName}</li>;
                  } else {
                    return (
                      <li key={index}>
                        <a href={item.pageURL}>{item.pageName}</a>
                      </li>
                    );
                  }
                }
              })}
            </ul>
          </nav>
        </div>
        <div className="col-12 col-sm-4 top-social-icons-group">
          <SocialIconsGroup data={socialLinksData} />
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
