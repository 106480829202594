import React from "react";


class PromoBanner extends React.Component {

	constructor(props){
		super()
	}


    render() {
      return (
		<div id="promo-banner">
		    <div className="container-fluid">
		        <div className="row">
		            <div className="col-12  list-container">
		                <div className="list">
		                    <h3>{this.props.data.heading}</h3>
		                    <ul>
		                        {this.props.data.links.map((pages, index) =>
		                        <li key={index}><a href={pages.pageURL}>{pages.pageName}</a></li>
		                        )}
		                    </ul>
		                </div>
		            </div>

		        </div>
		    </div>
		</div>
    );
  }
}

export default PromoBanner;
