import React from "react";

class QuickLinks extends React.Component {

constructor(props){
  super()
}


  render() {
    return (
      <div className="quick-links">
        <ul>
          {this.props.QuickLinks.map((pages, index) => 
            <li key={index} className={pages.linkColour}><a href={pages.pageURL}>{pages.pageName}</a></li>
          )}                                               
        </ul>
      </div>
    );
  }
}

export default QuickLinks;
