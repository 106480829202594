import {
    setDescriptionField,
    setPasswordField,
    setPrivacyField,
    setRequiredFields,
} from './FieldValidationHandlers';
import {
    exists,
    purgeValidFieldTracker,
    removeFromValidFieldTracker,
    checkAllFieldsOnLoad,
    setPlaceholders
} from './FieldUtils';

/**
 * Set the required fields for the forms.
 * 
 * Special character list retrieved from here: https://owasp.org/www-community/password-special-characters
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateFields = options => {
    const username_field = 'SQ_LOGIN_USERNAME';
    const description = options.selectors.description ? document.querySelector(options.selectors.description) : null;
    const password = options.selectors.pSelectors ? document.querySelector(options.selectors.pSelectors.password) : null;
    const errorList = options.selectors.pErrorList ? document.querySelector(options.selectors.pErrorList) : null;
    const privacy = options.selectors.privacy ? document.querySelector(options.selectors.privacy) : null;
    const submit = options.selectors.submit ? document.querySelector(options.selectors.submit) : null;
    const isEditableProfile = options.isEditableProfile;
    const passwordValidation = options.selectors.pSelectors ? {
        password,
        errorList,
        selectors: options.selectors.pSelectors,
        dNone: options.classes.dNone,
    } : null;
    const fieldPlaceholderValues = options.fieldPlaceholderValues;

    /**
     * The publicly exposed init function
     * 
     * @return {void}
     */
    const init = () => {
        if (exists([options.fields, submit])) {
            submit.disabled = true;

            // Used for the login form and reset password form.
            if (options.clearCustomFieldValidation) {
                purgeValidFieldTracker();
            }

            setRequiredFields(
                options.fields,
                username_field,
                submit
            );

            if (exists([privacy])) {
                setPrivacyField(
                    privacy,
                    submit
                );
            } else {
                removeFromValidFieldTracker('privacy', submit);
            }

            if (exists([password, errorList])) {
                setPasswordField(
                    passwordValidation,
                    submit
                );
            } else {
                removeFromValidFieldTracker('password', submit);
            }

            if (exists([description])) {
                setDescriptionField(
                    description,
                    options.classes.textDanger,
                    submit
                );
            }
            
            if (exists([fieldPlaceholderValues])) {
                setPlaceholders(fieldPlaceholderValues)
            }

            // On page load, we want to identify which fields are populated.
            if (isEditableProfile) {
                checkAllFieldsOnLoad(submit);
            }
        }
    };

    /**
     * Return the publicly exposed init function.
     */
    return Object.freeze({
        init
    });
};

export default CreateFields;