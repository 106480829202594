import React from "react";

class TextImagePromoCard extends React.Component {

	constructor(props){
		super()
		this.isImage = props.data.cardType == "image"
		this.isEvent = props.data.cardType == "event"		
	}

	state = {
      "heading": "quam nihil molestiae",
      "description": "in ea voluptate velit esse quam nihil molestiae consequatur, vel illum <a href='#''>qui dolorem eum</a> fugiat quo voluptas nulla pariatur?in ea voluptate velit esse quam nihil molestvoluptas nulla pariatur?",
      "imageURL": "dist/images/top-topics-placeholder-3.jpg",
      "imageAlt": "alt text here",
      "caption": "esse quam nihil molestiae consequatur"               
	}

  render() {
      return (
		 <div className={this.props.data.cardType  + " text-image-promo-card "}>
		     <div className="container-fluid">
		         <div className="row">
		             <div className={(this.isImage || this.isEvent) ? 'col-sm-12  col-md text-container ' : 'col-sm-12 text-container '}>
		                 {(this.props.data.cardType == "image" || this.props.data.cardType == "event")&&
		                   <h3><a href="#">{this.state.heading}</a></h3>
		                 }
		                 {this.props.data.cardType == "event" &&
		                   <p className="event-date">Friday, October 25 2019, 10:30am Bendigo Exhibition Centre</p>
		                 }


		                 <p dangerouslySetInnerHTML={{ __html: this.state.description}}/>

		                 {this.props.data.cardType != "text" &&
		                   <a class="button-white" href="http://google.com">CTA Button</a>
		                 }
		                 
		                 {this.props.data.cardType == "text" &&
			                 <ul>
			                   <li>quam nihil molestiae </li>
			                   <li>quam nihil molestiae </li>
			                   <li>quam nihil molestiae </li>
			                   <li>quam nihil molestiae </li>
			                   <li>quam nihil molestiae </li>
			                   <li>quam nihil molestiae </li>
			                 </ul>
		                 }  
		             </div>

		             {(this.props.data.cardType == "image" || this.props.data.cardType == "event") &&
			             <div className="col-sm-12 col-md-5 image-container">
			                 <a href="#">
			                     <div className="card-image" style={{backgroundImage: `url(${this.state.imageURL})`}} aria-label={this.state.imageAlt}>{this.state.imageAlt}</div>
			                 </a>
			                {this.props.data.cardType == "image" &&
			                     <span class="caption">{this.state.caption}</span>
			                }     

			             </div>
			         }  

		         </div>
		     </div>
		 </div>
    );
  }
}

export default TextImagePromoCard;
