function Accordion(){

var cards = document.querySelectorAll('.accordion .card-header')
var cardsKeypress = document.querySelectorAll('.accordion .card-header button')
var expand = document.querySelectorAll('.expand-all')
var collapse = document.querySelectorAll('.collapse-all')

//Events
for(var i = 0; i < cards.length ; i++){
  let element = cards[i]
  cards[i].addEventListener('click',  function(event){toggle(element,event)})
  cardsKeypress[i].addEventListener('keydown',  function(){
    let element = cardsKeypress[i].parentElement.parentElement

    toggle(element)
  })
}

if(typeof expand != 'undefined'){
  for(var i = 0; i < expand.length ; i++){
    let element = expand[i]
    expand[i].addEventListener('click', function(event){expandAll(element,event)})
    expand[i].addEventListener('keydown', function(event){expandAll(element,event)})
    collapse[i].addEventListener('click', function(event){collapseAll(element,event)})
    collapse[i].addEventListener('keydown', function(event){collapseAll(element,event)})
  }
}

//Toggle function for opening/closing each individual tab
var originalState = 0
function toggle(element,event){
  let thisAccordion = element.parentElement.parentElement
  let thisAccordionCardHeaders = thisAccordion.querySelectorAll('.accordion .card-header')
  let thisAccordionCardBodys = thisAccordion.querySelectorAll('.accordion .card-body')
  let thisAccordionExpandButton = thisAccordion.querySelectorAll('.expand-all')[0]
  let thisAccordionCollapseButton = thisAccordion.querySelectorAll('.collapse-all')[0]

  if (element.parentElement.getElementsByClassName('card-body')[0].style.height == "0px"){
    if(event.key == "Enter" || event.type == "click"){
      element.parentElement.getElementsByClassName('card-body')[0].style.height = "auto"
      element.parentElement.getElementsByClassName('card-body')[0].style.visibility = "visible";
      element.getElementsByClassName('accordion-closed')[0].classList.add("accordion-open");
      thisAccordionExpandButton.style.display = "inline";
      thisAccordionCollapseButton.style.display = "inline";
      originalState = originalState + 1;
      element.querySelectorAll('button')[0].setAttribute('aria-expanded', true)
   }
  }
  else{
    if(event.key == "Enter" || event.type == "click"){
      element.parentElement.getElementsByClassName('card-body')[0].style.height = "0px";
      element.parentElement.getElementsByClassName('card-body')[0].style.visibility = "collapse";
      element.getElementsByClassName('accordion-closed')[0].classList.remove("accordion-open");
      thisAccordionExpandButton.style.display = "inline";
      thisAccordionCollapseButton.style.display = "inline";
      originalState = originalState - 1;
      element.querySelectorAll('button')[0].setAttribute('aria-expanded', false)
    }
  }

  if(originalState == 0){
    thisAccordionCollapseButton.style.display = "none";
  }
  if(originalState == cards.length){
    thisAccordionExpandButton.style.display = "none";
  }

}

//Function for expanding all accordions
function expandAll(element,event){
  if(event.key == "Enter" || event.type == "click"){
    let thisAccordion = element.parentElement.parentElement.parentElement
    let thisAccordionCardHeaders = thisAccordion.querySelectorAll('.accordion .card-header')
    let thisAccordionCardBodys = thisAccordion.querySelectorAll('.accordion .card-body')
    let thisAccordionExpandButton = thisAccordion.querySelectorAll('.expand-all')[0]
    let thisAccordionCollapseButton = thisAccordion.querySelectorAll('.collapse-all')[0]

	for(var i = 0; i < thisAccordionCardBodys.length ; i++){
	  thisAccordionCardBodys[i].style.height = "auto"
    thisAccordionCardBodys[i].style.visibility = "visible";
	}
    thisAccordionCollapseButton.style.display = "inline";
    thisAccordionExpandButton.style.display = "none";
    originalState = thisAccordionCardHeaders.length

    for(var i = 0; i < thisAccordionCardHeaders.length ; i++){
      thisAccordionCardHeaders[i].getElementsByClassName('accordion-closed')[0].classList.add("accordion-open");
    }
  }
}

//Function for collapsing all accordions
function collapseAll(element,event){
  if(event.key == "Enter" || event.type == "click"){
    let thisAccordion = element.parentElement.parentElement.parentElement
    let thisAccordionCardHeaders = thisAccordion.querySelectorAll('.accordion .card-header')
    let thisAccordionCardBodys = thisAccordion.querySelectorAll('.accordion .card-body')
    let thisAccordionExpandButton = thisAccordion.querySelectorAll('.expand-all')[0]
    let thisAccordionCollapseButton = thisAccordion.querySelectorAll('.collapse-all')[0]

    for(var i = 0; i < thisAccordionCardBodys.length ; i++){
      thisAccordionCardBodys[i].style.height = "0px"
      thisAccordionCardBodys[i].style.visibility = "collapse";
    }

    thisAccordionExpandButton.style.display = "inline";
    thisAccordionCollapseButton.style.display = "none";

    originalState = 0;

    for(var i = 0; i < thisAccordionCardHeaders.length ; i++){
      thisAccordionCardHeaders[i].getElementsByClassName('accordion-closed')[0].classList.remove("accordion-open");
    }
  }
}



}; //End accordion


export { Accordion as default };
