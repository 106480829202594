import React from "react";

class RelatedEvents extends React.Component {

	constructor(props){
		super()

this.newProps = props.data

this.newProps = this.getUnique(this.newProps,'title')
	}

/*This function removes duplicate objects */
getUnique(arr, comp) {

  const unique = arr
       .map(e => e[comp])

     // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

   return unique;
}

    render() {
      return (
      <div id="related-events">
        <h2>Related events</h2>
         <ul>
         {this.newProps.map((item, index) => 
           <li key={index}><a href={item.url} >{item.title}</a> - <span>{item.startDate}</span></li>
          )}
          </ul>  
      </div>
    );
  }
}

export default RelatedEvents;
