import React from "react";
import QuickLinks from "../quick-links/QuickLinks";
import OverlayHomepageSVG from "./OverlayHomepageSVG";
import OverlayLandingSVG from "./OverlayLandingSVG";
import AutoComplete from "./auto-complete/AutoComplete";
import { getCmsData } from "../../functions";

class HeroBanner extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.HeroBanner.pageType == "homepage") {
      this.quickLinksData = getCmsData("QuickLinksDataCMS");
    }

    if (
      this.props.HeroBanner.pageType == "section" ||
      this.props.HeroBanner.pageType == "topic"
    ) {
      this.quickLinksTopicsData = getCmsData("QuickLinksDataTopicPageCMS");
    }
  }

  componentDidMount() {
    if (this.props.HeroBanner.category == "campaign") {
      if (this.props.HeroBanner.freeTextStatus == "on") {
        this.campaignRepositionHeading();
      }
    }
  }

  campaignRepositionHeading = () => {
    // get the heading
    var h1 = document.querySelector("h1");

    // detach the heading
    h1 = h1.parentNode.removeChild(h1);

    // attach the div after beginning of main
    var main = document.querySelector("main");
    main.insertAdjacentHTML("afterBegin", h1.outerHTML);
  };

  render() {
    return (
      <div
        className={"hero-banner-container " + this.props.HeroBanner.pageType}
      >
        {this.props.HeroBanner.pageType == "content" && (
          <div
            className={
              "hero-banner-icon-container " + this.props.HeroBanner.pageType
            }
          >
            {this.props.HeroBanner.icon.indexOf("none") == -1 && (
              <img
                className={"hero-banner-icon " + this.props.HeroBanner.pageType}
                alt="hero banner icon"
                src={this.props.HeroBanner.icon}
              />
            )}
          </div>
        )}

        {this.props.HeroBanner.pageType == "content" && (
          <section
            id="hero-banner"
            className={"hero-banner " + this.props.HeroBanner.pageType}
          ></section>
        )}

        {(this.props.HeroBanner.pageType == "homepage" ||
          this.props.HeroBanner.pageType == "section" ||
          this.props.HeroBanner.pageType == "topic" ||
          this.props.HeroBanner.pageType == "campaign") && (
          <section
            id="hero-banner"
            className={"hero-banner " + this.props.HeroBanner.pageType}
          >
            <div className="container-fluid">
              <div className="row middle-xs">
                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 pr-0 order-lg-2">
                  <div className="banner__image-wrapper">
                    {this.props.HeroBanner.pageType == "homepage" && (
                      <OverlayHomepageSVG />
                    )}

                    {this.props.HeroBanner.pageType != "homepage" && (
                      <React.Fragment>
                        <OverlayLandingSVG />
                        {(this.props.HeroBanner.pageType == "content" ||
                          this.props.HeroBanner.pageType == "topic") && (
                          <img
                            className={
                              "hero-banner-icon hero-banner-icon-mobile " +
                              this.props.HeroBanner.pageType
                            }
                            alt="hero banner icon"
                            src={this.props.HeroBanner.icon}
                          />
                        )}
                      </React.Fragment>
                    )}

                    <div
                      className={
                        "banner__image " + this.props.HeroBanner.pageType
                      }
                      alt={this.props.HeroBanner.bannerAlt}
                      style={{
                        backgroundImage: `url(${this.props.HeroBanner.banner})`
                      }}
                    ></div>
                  </div>
                </div>

                <div className="heading-container col-sm-12 col-md-12 col-lg-5 col-xl-4 order-lg-1">
                  <div className="banner__text-wrapper">
                    {this.props.HeroBanner.pageType == "topic" && (
                      <div>
                        <img
                          className="hero-banner-icon"
                          alt="hero banner icon"
                          src={this.props.HeroBanner.icon}
                        />
                      </div>
                    )}

                    {this.props.HeroBanner.pageType == "homepage" && (
                      <div>
                        <h1 className="banner__heading">
                          <a href={this.props.HeroBanner.linkurl}>
                            {this.props.HeroBanner.heading}
                          </a>
                        </h1>
                        <p>{this.props.HeroBanner.subheading}</p>
                      </div>
                    )}

                    {(this.props.HeroBanner.pageType == "section" ||
                      this.props.HeroBanner.pageType == "topic") &&
                      this.props.HeroBanner.category != "campaign" && (
                        <div className="heading-text-container">
                          <h1 className="banner__heading">
                            {this.props.HeroBanner.heading}
                          </h1>
                          <p className="banner-subheading">
                            {this.props.HeroBanner.subheading}
                          </p>
                        </div>
                      )}

                    {/* Campaign hero banner*/}
                    {this.props.HeroBanner.category == "campaign" && (
                      <div>
                        {this.props.HeroBanner.freeTextStatus == "off" && (
                          <div>
                            <h1 className="banner__heading">
                              {this.props.HeroBanner.heading}
                            </h1>
                            <p className="banner-subheading">
                              {this.props.HeroBanner.subheading}
                            </p>
                          </div>
                        )}
                        {this.props.HeroBanner.freeTextStatus == "on" && (
                          <div>
                            <h1 className="banner__heading">
                              {this.props.HeroBanner.heading}
                            </h1>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.props.HeroBanner.freeText
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {this.props.HeroBanner.pageType == "homepage" && (
                      <form
                        className="hero-banner-search"
                        action="https://agriculture.vic.gov.au/search"
                      >
                        <button
                          className="fa fa-search"
                          aria-label="Search"
                        ></button>
                        <AutoComplete
                          id="site-search"
                          name="query"
                          aria-label="Search through site content"
                          placeholder="How can we help?"
                        />
                        <input type="hidden" name="page" value="1" />
                      </form>
                    )}
                  </div>
                </div>
              </div>

              {this.props.HeroBanner.pageType == "homepage" &&
                this.quickLinksData && (
                  <div className="QuickLinks-container col-sm-12">
                    <QuickLinks QuickLinks={this.quickLinksData.pages} />
                  </div>
                )}
              {(this.props.HeroBanner.pageType == "section" ||
                this.props.HeroBanner.pageType == "topic") &&
                this.quickLinksTopicsData && (
                  <div className="QuickLinks-container col-sm-12">
                    <QuickLinks QuickLinks={this.quickLinksTopicsData.pages} />
                  </div>
                )}
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default HeroBanner;
