import React from "react";

class CodeBox extends React.Component {

	constructor(props){
		super()
	}

  render() {
      return (
		<div id="code-box">
		    <div className="container-fluid">
		        <div className="row">
		            <div className='col-sm-12  col-md-7 text-container'>
		                <p>{this.props.data.mainText}
		                </p>
		                <a className="button-white" href={this.props.data.buttonLink}>{this.props.data.buttonText}</a>
		            </div>
		            <div className="col-sm-12 col-md-5 code-container">
		                <ul>
		                    <li>{this.props.data.code[0]}</li>
		                    <li>{this.props.data.code[1]}</li>
		                    <li>{this.props.data.code[2]}</li>
		                    <li>{this.props.data.code[3]}</li>
		                    <li>{this.props.data.code[4]}</li>
		                    <li>{this.props.data.code[5]}</li>
		                    <li>{this.props.data.code[6]}</li>
		                    <li>{this.props.data.code[7]}</li>
		                </ul>
		                <p>{this.props.data.belowCodeText}</p>
		            </div>
		        </div>
		    </div>
		</div>
    );
  }
}

export default CodeBox;
