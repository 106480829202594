import React from "react";

class SelectionBox extends React.Component {

	constructor(props){
		super()
	}

  render() {
      return (
		<div id="selection-box">
    <h2>{this.props.data.heading}</h2>
    <p>{this.props.data.textAboveButton}</p>
    <div className="container-fluid buttons-container">
      <div className="row">
        <div className="col-sm col-sm-12 button-container">
         {this.props.data.button1LinkExternal.length < 3 &&         
          <a  className="button-white" href={this.props.data.button1Link}>{this.props.data.button1Text}</a>
         }
         {this.props.data.button1LinkExternal.length > 3 &&         
          <a  className="button-white" target="_blank" href={this.props.data.button1LinkExternal}>{this.props.data.button1Text}</a>
         }         
	       {(this.props.data.button2LinkExternal.length < 3 && this.props.data.button2Link.length > 3) &&  
	          <a className="button-white" href={this.props.data.button2Link}>{this.props.data.button2Text}</a>
	       }
         {this.props.data.button2LinkExternal.length > 3 &&  
            <a className="button-white" target="_blank" href={this.props.data.button2LinkExternal}>{this.props.data.button2Text}</a>
         }             
 </div>
      </div>
    </div>    
    <p>{this.props.data.textBelowButton}</p>
		</div>
    );
  }
}

export default SelectionBox;
