import React from "react";

class WasThisHelpful extends React.Component {
	constructor(){
     super()
     this.state = {responseSent: "no"}
	}




    response   = (answer) => {
      /* ga('send', 'event', 'Video', 'play', 'cats.mp4'); */
      this.thankYou()
    }

    thankYou   = () => {
      this.setState({
        "responseSent": "yes"
      });
    }    

    render() {
      return (
       <div id="was-this-helpful">
         <span className="heading">Was this page helpful?</span>
         {this.state.responseSent == "no" &&  
           <div>  
             <button className="yes" onClick={() => this.response('yes')}>Yes</button>
             <button className="no" onClick={() => this.response('no')}>No</button>
           </div>
         }
         {this.state.responseSent == "yes" &&         
           <p>Thank you for your feedback</p>
         }
       </div>
    );
  }
}

export default WasThisHelpful;
