import React from "react";

class SocialIconsGroup extends React.Component {

        constructor(props) {
            super(props)
            this.state = {
                "facebook": "https://facebook.com/sharer.php?u=" + window.location.href + "&" + "t=",
                "email": "mailto:?subject=Agriculture Victoria Website Content:" + props.data.pageName + "&" + "body=Hi. I found some information that might interest you:" + props.data.pageName + " - " + window.location.href,
                "print": "javascript:window.print()",
                "twitter": "https://twitter.com/share?text=" + props.data.pageName + "&" + "url=" + window.location.href
            }
            if(props.data.location == "footer"){
              this.updateForFooter()
            }
        }


        componentDidMount = () => {
            window.onpopstate = (event) => {
               setTimeout(() => {
                if(props.data.location == "top"){
                   this.updateForSearchPage()
                }
               }, 1000);
                

            }
        }

        updateForSearchPage = () => {
            this.state = {
                "facebook": "https://facebook.com/sharer.php?u=" + window.location.href + "&" + "t=",
                "twitter": "https://twitter.com/share?text=" + props.data.pageName + "&" + "url=" + window.location.href,
                "email": "mailto:?subject=Agriculture Victoria Website Content:" + this.props.data.pageName + "&" + "body=Hi. I found some information that might interest you:" + this.props.data.pageName + " - " + window.location.href,
            }
           document.querySelector('#facebook-social-link').setAttribute('href', this.state.facebook)
           document.querySelector('#twitter-social-link').setAttribute('href', this.state.twitter)
           document.querySelector('#email-link').setAttribute('href', this.state.email)
        }

        updateForFooter = () => {
            this.state = {
                "facebook": this.props.data.facebook,
                "twitter": this.props.data.twitter,
                "youtube": this.props.data.youtube
            }

        }        

render() {
return (
<div className="social-icons-group">
    {this.props.data.location == "top" &&
    <a href={this.state.email} id="email-link" className="social-link" aria-label="Email this page" target="_blank">
        <svg width="28px" height="25px" viewBox="0 0 22 16">
            <g id="icon-email" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                <path d="M20,13.2 C20,13.642 19.6599,14 19.24,14 L1.76,14 C1.3401,14 1,13.642 1,13.2 L1,2.8 C1,2.358 1.3401,2 1.76,2 L19.24,2 C19.6599,2 20,2.358 20,2.8 L20,13.2 Z" id="Stroke-1"></path>
                <path d="M14,8 L20,14" id="Stroke-3" stroke="#ffffff"></path>
                <path d="M1,14 L7,8" id="Stroke-5" stroke="#ffffff"></path>
                <path d="M20,2 L11.3454015,10.6500169 C10.8781593,11.116661 10.121054,11.116661 9.65381192,10.6500169 L1,2" id="Stroke-7" stroke="#ffffff"></path>
            </g>
        </svg>
    </a>
    }
    {this.props.data.location == "top" &&
    <a href={this.state.print} id="print-link" className="social-link" aria-label="Print this page">
        <svg width="28px" height="25px" viewBox="0 0 18 18">
            <g id="icon-printer" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                <path d="M17.3897,9 C17.78835,9 18,9.336 18,9.72223529 L18,15.0974118 C18,15.4836471 17.78835,16 17.3897,16 L1.72165,16 C1.323,16 1,15.4836471 1,15.0974118 L1,9.72223529 C1,9.336 1.323,9 1.72165,9 L17.3897,9 Z" id="Stroke-1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M4,8.72253788 L4,2.73695821 C4,2.34284943 4.33713352,2 4.75322725,2 L14.0093167,2 C14.4254104,2 14.6463216,2.34284943 14.6463216,2.73695821 L14.6463216,8.72253788" id="Stroke-5" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.11156794,6 L12.765519,6" id="Stroke-9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M3.32438151,15.9411103 L3.32438151,13.8516615 C3.32438151,13.4575527 3.5277869,13 3.91090789,13 L14.8841792,13 C15.2673002,13 15.5777181,13.4575527 15.5777181,13.8516615 L15.5777181,15.9411103" id="Stroke-3" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.11156794,4 L10.5475353,4" id="Stroke-7" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M16.1554362,10.5777181 C16.1554362,10.8966185 15.8966185,11.1554362 15.5777181,11.1554362 C15.2588177,11.1554362 15,10.8966185 15,10.5777181 C15,10.2588177 15.2588177,10 15.5777181,10 C15.8966185,10 16.1554362,10.2588177 16.1554362,10.5777181" id="Fill-11" fill="#202228"></path>
            </g>
        </svg>
    </a>
    }

    <a href={this.state.facebook} id="facebook-social-link" className="social-link" aria-label="Facebook" target="_blank">
        <svg width="23px" height="23px" viewBox="0 0 20 20">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Footer">
                    <path id="Facebook_logo" data-name="Facebook logo" className="cls-1" d="M18.9,0H1.1A1.1,1.1,0,0,0,0,1.1V18.9A1.1,1.1,0,0,0,1.1,20h9.58V12.25H8.08v-3h2.6V7a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H15.3c-1.26,0-1.5.6-1.5,1.47V9.24h3l-.39,3H13.8V20h5.1A1.1,1.1,0,0,0,20,18.9V1.1A1.1,1.1,0,0,0,18.9,0Z" />
                </g>
            </g>
        </svg>
    </a>


    <a href={this.state.twitter} id="twitter-social-link" className="social-link" aria-label="Twitter" target="_blank">
        <svg width="26px" height="21px" viewBox="0 0 26 21">
            <g id="Twitter" stroke="none" strokeWidth="1" fillRule="evenodd">
                <path d="M23.2015425,5.22454163 L23.2189997,5.91119014 C23.2189997,12.9192722 17.8858357,21 8.13018981,21 C5.13434628,21 2.34798873,20.1213227 0,18.6141874 C0.415092597,18.6607399 0.836974091,18.6869256 1.26564448,18.6869256 C3.75038101,18.6869256 6.03726966,17.8421928 7.85184501,16.4204036 C5.53198171,16.3787004 3.57386967,14.8453794 2.89885928,12.7369418 C3.22375652,12.8009514 3.55447282,12.8348959 3.89682723,12.8348959 C4.38174849,12.8348959 4.85018242,12.7689466 5.2943703,12.6496559 C2.869764,12.1618252 1.04064102,10.0175033 1.04064102,7.44936037 L1.04064102,7.38244123 C1.7563848,7.78007666 2.57396204,8.01962777 3.44294093,8.04872304 C2.0211518,7.09827737 1.08428393,5.474761 1.08428393,3.63496975 C1.08428393,2.66221771 1.34420173,1.75153558 1.80196739,0.968872674 C4.41763266,4.17808156 8.32512816,6.28942872 12.7330624,6.51055281 C12.6418972,6.1226158 12.5953448,5.71722163 12.5953448,5.30212904 C12.5953448,2.37514432 14.9704891,0 17.8994135,0 C19.4259456,0 20.8021521,0.643005588 21.770055,1.67491803 C22.9784787,1.43633677 24.1122246,0.995058421 25.1402577,0.387937006 C24.7426223,1.62351637 23.9046783,2.66415739 22.8087563,3.31977093 C23.8814021,3.19175172 24.9026463,2.90564818 25.8540618,2.48473653 C25.1451069,3.54768392 24.2431534,4.48164227 23.2015425,5.22454163"></path>
            </g>
        </svg>
    </a>

    {this.props.data.location == "footer" &&
    <a href={this.props.data.youtube} className="social-link" aria-label="YouTube" target="_blank">
        <svg width="31px" height="23px" viewBox="0 0 31 23">

            <g id="youtube" stroke="none" strokeWidth="1" fillRule="evenodd">
                <path d="M12,17 L12,7 L20,12.0001911 L12,17 Z M30.3521705,3.59152419 C29.9956705,2.17776613 28.9451932,1.06449194 27.6114886,0.68666129 C25.194017,0 15.5,0 15.5,0 C15.5,0 5.80598295,0 3.38851136,0.68666129 C2.05480682,1.06449194 1.00432955,2.17776613 0.647829545,3.59152419 C0,6.15379839 0,11.5 0,11.5 C0,11.5 0,16.8462016 0.647829545,19.4084758 C1.00432955,20.8222339 2.05480682,21.9355081 3.38851136,22.3135242 C5.80598295,23 15.5,23 15.5,23 C15.5,23 25.194017,23 27.6114886,22.3135242 C28.9451932,21.9355081 29.9956705,20.8222339 30.3521705,19.4084758 C31,16.8462016 31,11.5 31,11.5 C31,11.5 31,6.15379839 30.3521705,3.59152419 Z" id="YouTube"></path>
            </g>
        </svg>
    </a>
    }
</div>
);
}
}

export default SocialIconsGroup;
