function Tables(){

	// declaring vars
	var contentTable = document.querySelectorAll('main table');
	var tableResponsive = document.getElementsByClassName('table-responsive');
	var tableWrap = document.getElementsByClassName('table-wrap');
	var scrollLabel = document.getElementsByClassName('scroll-label');

	for(var i = 0; i < contentTable.length; i++){

	//wrap each table current implmentation 
	var wrapResponsive = document.createElement('div');
	wrapResponsive.setAttribute('class','table-responsive')
	contentTable[i].parentNode.insertBefore(wrapResponsive, contentTable[i]);
	wrapResponsive.appendChild(contentTable[i]);
	

	//wrap each table responsive to control toggle
	var wrapper = document.createElement('div');
	wrapper.setAttribute('class','table-wrap')
	tableResponsive[i].parentNode.insertBefore(wrapper, tableResponsive[i]);
	wrapper.appendChild(tableResponsive[i]);
	
	//add scroll label to the bottom
	tableWrap[i].insertAdjacentHTML('beforeend', '<div class="scroll-label d-none">Scroll for more content</div>');

	}

	//loop through tables toggle class to show right side shards
	for(var i = 0; i < tableResponsive.length; i++){

		if (contentTable[i].offsetWidth > tableResponsive[i].offsetWidth){
			tableWrap[i].classList.add("table-overflow");
			scrollLabel[i].classList.remove("d-none");


		}else if(contentTable[i].offsetWidth < tableResponsive[i].offsetWidth) {
			tableWrap[i].classList.remove("table-overflow");
			scrollLabel[i].classList.add("d-none");

		}
	}

	//Watch for window resize
	window.addEventListener('resize', function() {
		for(var i = 0; i < tableResponsive.length; i++){

			if (contentTable[i].offsetWidth > tableResponsive[i].offsetWidth){
				tableWrap[i].classList.add("table-overflow");
				scrollLabel[i].classList.remove("d-none");
				
	
			}else if(contentTable[i].offsetWidth <= tableResponsive[i].offsetWidth) {
				tableWrap[i].classList.remove("table-overflow");
				scrollLabel[i].classList.add("d-none");
	
			}
		}
		
	}, true);

}; //End Tables
  



export { Tables as default };















