function ImageCaptions(){

  var imageEl = document.querySelectorAll('main #standard-content-page img')
  for (var i = 0; i < imageEl.length ; i++){
    if (imageEl[i].getAttribute('title') != null ){
     if (imageEl[i].getAttribute('title').length > 0 ){     
       attachCaptions(i)
      }
      else{
        noCaptions()
      }
    }  
  }

  function attachCaptions(i){
    let counter = i
    let imageTitle = imageEl[counter].getAttribute('title')
    let wrapper = document.createElement('div');
    let captionWrapper = document.createElement('p');


    imageEl[counter].style.margin = "0px";
    if(imageEl[counter].parentElement.nodeName == "A"){
      imageEl[counter].parentElement.parentNode.insertBefore(wrapper, imageEl[counter].parentElement);
    }
    else{
      imageEl[counter].parentNode.insertBefore(wrapper, imageEl[counter]);
    }

    wrapper.classList.add("image-container");
    captionWrapper.classList.add("caption");    
    wrapper.style.float = imageEl[counter].style.float
    wrapper.setAttribute('width' , imageEl[counter].getAttribute('width')) 
    wrapper.style.width = imageEl[counter].getAttribute('width') + "px";
    if(imageEl[counter].parentElement.nodeName == "A"){
      wrapper.appendChild(imageEl[counter].parentElement);
    }
    else{
      wrapper.appendChild(imageEl[counter]);
    }
    wrapper.appendChild(captionWrapper);

    captionWrapper.innerHTML = imageTitle

    if(imageEl[counter].style.float == ""){
      // wrapper.style.margin = "0px auto";
    }
    if(imageEl[i].style.float == "left"){
      wrapper.style.marginRight = "25px";
    }
    if(imageEl[i].style.float == "right"){
      wrapper.style.marginLeft = "25px";
    }

    imageEl[counter].style.float = "none";
  }

  function noCaptions(){
    if(imageEl[i].style.float == "left"){
      imageEl[i].style.margin = "5px 25px 10px 0px";
    }
    if(imageEl[i].style.float == "right"){
      imageEl[i].style.margin = "5px 0px 10px 25px";     
    } 
  }

}; //End images

export { ImageCaptions as default };

