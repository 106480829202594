import {
    submitButtonHandler,
    exists,
    removeFromValidFieldTracker
} from './FieldUtils';

/**
 * Create the email field using the Matrix username field.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateConfirmEmailField = options => {
    const usernameWrapper = document.querySelector(options.selectors.usernameWrapper);
    const usernameDesc = document.querySelector(options.selectors.usernameDesc);
    const emailConfirmation = document.querySelector(options.selectors.emailConfirmation);
    const emailError = document.querySelector(options.selectors.emailError);
    const submit = document.querySelector(options.selectors.submit);
    const isEditableProfile = options.isEditableProfile;
    let hiddenEmailField = null;
    let usernameField = null;

    /**
     * Set event listener to transfer the value of username to the email
     * every time keyup event is fired.
     * 
     * @params {string} value
     * 
     * @returns {void}
     */
    const __duplicateUsernameToEmail = value => {
        hiddenEmailField.value = value;
    };

    /**
     * Take the username and use it as an email field for logins.
     * 
     * @returns {void}
     */
    const __setReplacementEmailField = () => {
        hiddenEmailField.type = 'hidden';

        exists([usernameDesc]) ? usernameDesc.parentNode.removeChild(usernameDesc) : null;

        usernameField.addEventListener('keyup', e => {
            __duplicateUsernameToEmail(e.target.value);
        });
    };

    /**
     * Toggle the email confirmation error depending on the value of hide.
     * 
     * @param {boolean} hide 
     * 
     * @returns {void}
     */
    const __hideEmailConfirmationError = hide => {
        if (!hide) {
            emailError.classList.remove(options.classes.dNone);
            emailError.classList.add(options.classes.dBlock);
        } else {
            emailError.classList.add(options.classes.dNone);
            emailError.classList.remove(options.classes.dBlock);
        }
    };

    /**
     * Logic for the email validation.
     * 
     * @returns {void}
     */
    const __handleEmailValidation = () => {
        const originalEmail = usernameField.value;
        const confirmedEmailValue = emailConfirmation.value;
        let valid = true;

        if (confirmedEmailValue !== originalEmail) {
            valid = false;
        }
        
        __hideEmailConfirmationError(valid);
        submitButtonHandler('confirm_email', valid, submit);
    };

    /**
     * Set the confirmation email field validation
     * 
     * @returns {void}
     */
    const __bind = () => {
        emailConfirmation.addEventListener('keyup', () => {
            __handleEmailValidation();
        });

        usernameField.addEventListener('keyup', () => {
            __handleEmailValidation();
        });
    };

    /**
     * Publicly exposed init function.
     * 
     * @returns {void}
     */
    const init = () => {
        let requiredComponents = [
            usernameWrapper,
            emailConfirmation,
            emailError,
            submit
        ];

        if (exists(requiredComponents) && isEditableProfile === false) {
            hiddenEmailField = document.querySelector(`#${usernameWrapper.dataset.emailSelector}`);
            usernameField = document.querySelector(`#${usernameWrapper.dataset.usernameSelector}`);
            
            requiredComponents = [hiddenEmailField, usernameField];

            if (exists(requiredComponents)) {
                __setReplacementEmailField();
                __bind();
            }
        } else if (isEditableProfile === true) {

            if (exists([usernameWrapper, submit])) {
                hiddenEmailField = document.querySelector(`#${usernameWrapper.dataset.emailSelector}`);
                usernameField = document.querySelector(`#${usernameWrapper.dataset.usernameSelector}`);
                
                requiredComponents = [hiddenEmailField, usernameField];
                
                if (exists(requiredComponents)) {
                    // No need for a confirm email field
                    removeFromValidFieldTracker('confirm_email', submit)

                    __setReplacementEmailField();
                }
            }
        }
    };

    /**
     * Return publicly exposed init function.
     */
    return Object.freeze({
        init,
    });
};

export default CreateConfirmEmailField;