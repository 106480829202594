import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { debounce } from 'throttle-debounce';

const getSuggestionValue = suggestion => suggestion.title;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => ( 
    <div id="results-container" tabIndex="0">
      <a href = {suggestion.displayUrl} > {suggestion.title} </a>
    </div>
);

class AutoComplete extends React.Component {
    constructor() {
        super();

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: []
        };
    }

    onChange = (event, {
        newValue, method
    }) => {
        if(method == 'type'){
            this.setState({
                value: newValue
            });
        }
    };

  componentWillMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    )
  }

    componentDidMount(){
        document.querySelectorAll('.react-autosuggest__input')[0].setAttribute('aria-describedby','initInstr')

        //Keyboard accessibility navigation for autosuggest results
        document.querySelectorAll('.react-autosuggest__input')[0].addEventListener('keyup', function(e){
            if(e.which == "40" || e.which == "38"){
              var suggestionItems = document.querySelectorAll('.react-autosuggest__suggestion')
              for(var i = 0; i < suggestionItems.length; i++){
                suggestionItems[i].style.backgroundColor = "#fff"  
              }              
              document.querySelectorAll('.react-autosuggest__suggestion--highlighted')[0].style.backgroundColor = "#ddd"
            }
        })

    }



    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({value}) => {
     
        var API = "https://agriculture.vic.gov.au/_design/data-files/auto-suggest?query=" + value
        let seeAllResultsDiv = "https://agriculture.vic.gov.au/search?query=" + value
        let seeAllResultsText = "Search the site for: " + value
  
        if (value.length > 2) {
            fetch(API)
                .then(response => response.json())
                .then((data) => {
                    if(data.response.resultPacket.results.length > 0 ){
                      data.response.resultPacket.results.push({title: seeAllResultsText  , displayUrl: seeAllResultsDiv})
                    }
                    this.setState({
                        suggestions: data.response.resultPacket.results
                    });
                })
        }
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    //This function overrides default behaviour when using the keyboard to navigate through suggestions and selecting one
    redirectOnSuggestionSelected = (e, value) => {
        e.preventDefault();
        window.location.href = value.suggestion.displayUrl;
    }
    
    render() {
        const {value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Type here to get search suggestions',
            value,
            name: 'query',
            title: 'Search',
            onChange: this.onChange
        };

        // Finally, render it!
        return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion={false}
        onSuggestionSelected={this.redirectOnSuggestionSelected}
      />
    );
    }
}


export default AutoComplete;