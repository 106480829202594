import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";

/**
 * Returns a globally-defined CMS variable, otherwise logs a console error.
 * @param {string} windowVarName Global window variable name to query.
 * @returns {any}
 */
export function getCmsData(windowVarName) {
  if (typeof window[windowVarName] === "undefined") {
    console.error(
      `Component data not found! 'window.${windowVarName}' is undefined.`
    );
    return null;
  }
  return window[windowVarName];
}

/**
 * Renders a React component, wrapped in an ErrorBoundary.
 * @param {Function} Component The default export function for your React component.
 * @param {string} querySelector The selector to locate the DOM element to render your React component into.
 * @param {Object} props The properties to pass on to the rendered component.
 */
export function renderWithErrorBoundary(
  Component,
  targetElement,
  props = null
) {
  ReactDOM.render(
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>,
    targetElement
  );
}

export function usingLocalData() {
  let useLocalData = false;

  if ("development" === process.env.NODE_ENV) {
    useLocalData = true;
  }

  if (window.location.href.indexOf("localhost") > -1) {
    useLocalData = true;
  }

  if (window.location.href.indexOf("0008/491966") > -1) {
    useLocalData = true;
  }

  if (window.location.href.indexOf(".netlify.app") > -1) {
    useLocalData = true;
  }

  return useLocalData;
}

export function getFacebookFeedUrl() {
  return "https://agriculture.vic.gov.au/_design/data-files/facebook-data.json";
}
