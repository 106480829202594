import React from "react";


class StandardCard extends React.Component {
	constructor(props){
		super()
	}

   render() {
      return (
		<div className="section-card">
		  <a href={this.props.data.URL}>
		   <h3>{this.props.data.heading}</h3>
		   <div className="image-container">

		     <div className="section-card-image" aria-label={this.props.data.imageAlt} style={{ backgroundImage: `url(${this.props.data.imageURL})` }}><span>{this.props.data.imageAlt}</span>
             <div className="section-card-image-overlay"></div>
		     </div>
		   </div>
		   <p>{this.props.data.description}</p>
		  </a>  
		</div>  
    );
  }
}

export default StandardCard;
