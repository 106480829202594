import React from "react";

class TraceabilityDirectory extends React.Component {
    constructor (props) {
        super();
        
        this.getDirectory = this.getDirectory.bind(this);
        this.getProfiles = this.getProfiles.bind(this);
        this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    }

    capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getImageAndClassification (profile) {
        const {img, classification} = profile;
        const image = img.length > 0 ? img : 'https://via.placeholder.com/214';

        return (
            <div className="traceability-directory-profile__image"
                style={{ backgroundImage: `url(${ image })` }}
            >
                {classification.length > 0 &&
                    <span className="traceability-directory-profile__classification">
                        {this.capitalizeFirstLetter(classification)}
                    </span>
                }
            </div>
        );
    }
    
    getContent (profile) {
        const { email, linkedin, name, organisation, shortDesc } = profile;

        return (
            <div className="traceability-directory-profile__content">
                {name.length > 0 && <p className="traceability-directory-profile__name">{name}</p>}
                {organisation.length > 0 && <p className="traceability-directory-profile__org">{organisation}</p>}
                {shortDesc.length > 0 && <p className="traceability-directory-profile__shortdesc">{shortDesc}</p>}
                {email.length > 0 && <a href={`mailto:${email}`} className="traceability-directory-profile__email"><span>{email}</span></a>}
                {linkedin.length > 0 && <a href={linkedin} target="_blank" rel="noopener noreferrer" className="traceability-directory-profile__linkedin"><span>{linkedin.replace('https://', '')}</span></a>}
            </div>
        );
    }

    getProfiles () {
        const { profiles } = this.props.data;
        const elements = [];
        
        profiles.forEach((profile, i) => {
            const profileClass = i > 1 ?
                'traceability-directory-profile traceability-directory-profile--mt' :
                'traceability-directory-profile'
            ;

            elements.push(
                <div className="col col-lg-6" key={i}>
                    <div className={profileClass}>
                        {this.getImageAndClassification(profile)}
                        {this.getContent(profile)}
                    </div>
                </div>
            );
        });

        return elements;
    }

    getDirectory () {
        return (
            <section className="traceability-directory-listing">
                <div className="row">
                    {this.getProfiles()}
                </div>
            </section>
        );
    }

    render() {
        const { heading, summary, profiles } = this.props.data;

        return (
            <>
                {heading.length > 0 && <h1 className="traceability-directory__heading">{heading}</h1>}
                {summary.length > 0 && <p className="traceability-directory__summary">{summary}</p>}
                {profiles.length > 0 && this.getDirectory()}
            </>
        );
    }
}

export default TraceabilityDirectory;