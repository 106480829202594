import React from "react";

class TraceabilityDirectoryEditProfileSidebar extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const { editURL, logoutURL } = this.props.data;

        return (
            <>
                <h3>My account <a href={editURL}>Edit profile</a></h3>
                <a href={`${logoutURL}?SQ_ACTION=logout`} className="button-black2">Logout</a>
            </>
        );
    }
}

export default TraceabilityDirectoryEditProfileSidebar;