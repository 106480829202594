import React from "react";

class StandardCard extends React.Component {
	constructor(props){
		super()
    this.newWindow = ""
	}

   render() {
      let cardTypeSocial = this.props.data.cardType == "social"
      this.newWindow = cardTypeSocial ? '_blank' : '_self'
      return (
		<div className="col-12 col-sm-12 col-lg standard-card">
		    <div className="row">
		        <div className="col-12 col-sm-12 col-md col-lg-6 order-2 order-md-1">
                    {/* Ony print the heading if not a facebook card */}
                    {!cardTypeSocial &&
                        <h3><a href={this.props.data.URL}>{this.props.data.heading}</a></h3>
                    }
                    {/* Add facebook logo if facebook card*/}
		            {cardTypeSocial &&
                      <span className="fa fa-facebook-square"></span>
                    }
                    {/* Add facebook logo if facebook card*/}
		            {(cardTypeSocial || this.props.data.cardType == "events")  &&
                      	<time dateTime="2008-02-14 20:00">{this.props.data.date}</time>
                    }                    

		            <p>{this.props.data.description}</p>
                    {/* Add facebook logo if facebook card*/}                    
                    {cardTypeSocial &&
                         <a href={this.props.data.URL} className="text-button" target="_blank">Read full post</a>
                    }
		        </div>
		        <div className="col-sm col-md-4 col-lg-6 order-1 order-md-2 image-container">
		            <a href={this.props.data.URL} target={this.newWindow}>
		                <div className="standard-card-image" style={{backgroundImage: `url(${this.props.data.imageURL})`}} aria-label={this.props.data.imageAlt}><span>{this.props.data.imageAlt}</span></div>
		            </a>
		        </div>
		    </div>
		</div>  
    );
  }
}

export default StandardCard;
