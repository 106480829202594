import CreateFields from "./CreateFields";
import CreateConfirmEmailField from "./CreateConfirmEmailField";
import CreateOtherFields from "./CreateOtherFields";
import CreateConfirmPasswordField from "./CreateConfirmPasswordField";

window.addEventListener("load", () => {
  let requiredFields = window.traceabilityform
    ? window.traceabilityform.requiredFields
    : null;

  let fieldPlaceholderValues = window.traceabilityform
    ? window.traceabilityform.fieldPlaceholderValues
    : null;

  const EditProfileFields = new CreateFields({
    clearCustomFieldValidation: false,
    isEditableProfile: true,
    fields: requiredFields,
    fieldPlaceholderValues: fieldPlaceholderValues,
    selectors: {
      description:
        '.edit-profile-section .description-field input[type="text"]',
      pErrorList: ".edit-profile-section .password-errors",
      privacy: ".edit-profile-section .traceability-form__privacy table input",
      submit: ".edit-profile-section #sq_commit_button",
    },
    classes: {
      dNone: "d-none",
      textDanger: "text-danger",
    },
  });

  const RegisterFields = new CreateFields({
    clearCustomFieldValidation: false,
    isEditableProfile: false,
    fields: requiredFields,
    fieldPlaceholderValues: fieldPlaceholderValues,
    selectors: {
      description: '.register-section .description-field input[type="text"]',
      pErrorList: ".register-section .password-errors",
      privacy: ".register-section .traceability-form__privacy table input",
      submit: ".register-section #sq_commit_button",
      pSelectors: {
        password: ".register-section input[type=password]",
        pCapitalChars: ".register-section .password-errors__capital",
        pIntegerChars: ".register-section .password-errors__number",
        pLength: ".register-section .password-errors__length",
        pSpecialChars: ".register-section .password-errors__special",
      },
    },
    classes: {
      dNone: "d-none",
      textDanger: "text-danger",
    },
  });

  const LoginFields = new CreateFields({
    clearCustomFieldValidation: true,
    isEditableProfile: false,
    fields: requiredFields,
    fieldPlaceholders: [],
    selectors: {
      submit: ".traceability-form-login #log_in_out_button",
    },
  });

  const ResetPasswordFields = new CreateFields({
    clearCustomFieldValidation: true,
    isEditableProfile: false,
    fields: requiredFields,
    fieldPlaceholders: [],
    selectors: {
      pErrorList: ".traceability-form-reset .password-errors",
      submit: '.traceability-form-reset input[type="submit"]',
      pSelectors: {
        password: ".traceability-form-reset input[type=password]",
        pLength: ".traceability-form-reset .password-errors__length",
        pCapitalChars: ".traceability-form-reset .password-errors__capital",
        pIntegerChars: ".traceability-form-reset .password-errors__number",
        pSpecialChars: ".traceability-form-reset .password-errors__special",
      },
    },
    classes: {
      dNone: "d-none",
      textDanger: "text-danger",
    },
  });

  const ConfirmPasswordField = new CreateConfirmPasswordField({
    clearCustomFieldValidation: true,
    // fields: window.traceabilityform.requiredFields,
    selectors: {
      confirmPassword:
        'div[data-name="confirm_password"] input[type="password"]',
      password: 'div[data-name="password"] input[type="password"]',
      passwordError:
        '.traceability-form-reset [data-name="confirm_password"] .field-error',
      submit: '.traceability-form-reset input[type="submit"]',
    },
    classes: {
      dBlock: "d-block",
      dNone: "d-none",
    },
  });

  const ConfirmEmailField = new CreateConfirmEmailField({
    fields: requiredFields,
    isEditableProfile: false,
    selectors: {
      usernameWrapper: ".register-section [data-username-selector]",
      usernameDesc: ".register-section .sq-backend-smallprint",
      emailConfirmation: ".register-section #confirm_email",
      emailError: ".register-section .field-error",
      submit: ".register-section #sq_commit_button",
    },
    classes: {
      dNone: "d-none",
      dBlock: "d-block",
    },
  });

  const EditableProfileEmailField = new CreateConfirmEmailField({
    fields: requiredFields,
    isEditableProfile: true,
    selectors: {
      usernameWrapper: ".edit-profile-section [data-username-selector]",
      usernameDesc: ".edit-profile-section .sq-backend-smallprint",
      emailConfirmation: ".edit-profile-section #confirm_email",
      emailError: ".edit-profile-section .field-error",
      submit: ".edit-profile-section #sq_commit_button",
    },
    classes: {
      dNone: "d-none",
      dBlock: "d-block",
    },
  });

  const OtherFields = new CreateOtherFields({
    selectors: {
      otherFieldWrapper: ".traceability-form .other-field",
      submit: ".traceability-form #sq_commit_button",
    },
    classes: {
      dNone: "d-none",
      dBlock: "d-block",
      otherFieldInput: "other-field__input",
    },
  });

  // Init all instances of the form fields.
  ResetPasswordFields.init();
  LoginFields.init();
  RegisterFields.init();
  ConfirmEmailField.init();
  EditableProfileEmailField.init();
  OtherFields.init();
  ConfirmPasswordField.init();
  EditProfileFields.init();
});

/**
 * Note: To test submissions locally and view values prior to submission,
 * view this resource: https://stackoverflow.com/questions/10955745/get-values-from-submitted-form
 */
