import React, { useState } from "react";
import { getCmsData } from "../../../functions";

const Subscribe = () => {
  const cmsData = getCmsData("subscribeDataCMS");
  if (!cmsData) return null;

  const { enabled, newsletters } = cmsData;

  const [state, setState] = useState({ selectedNewsletter: "" });

  const onSelect = event => {
    setState({
      selectedNewsletter: event.target.value
    });
  };

  return (
    <section id="subscribe" className={enabled}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-4 message-container">
            <h2>Sign up for updates</h2>
          </div>
          <div className="col-12 col-lg-8 ">
            <form
              className="js-cm-form subscribe-search"
              id="subForm"
              action="https://confirmsubscription.com/t/r/HostedSubscribeForm/odhthb"
              method="post"
              data-id={state.selectedNewsletter}
            >
              <div className="row">
                <div className="col-12 col-lg-6 dropdown-container">
                  <label htmlFor="subscribeselect">
                    Example multiple select
                  </label>
                  <select
                    className="form-control  input-lg"
                    id="subscribeselect"
                    onChange={onSelect}
                    required
                  >
                    <option value="">Choose a newsletter</option>
                    {newsletters.map((id, index) => (
                      <option key={index} value={id.id}>
                        {id.name}
                      </option>
                    ))}
                  </select>
                  <i className="fa fa-chevron-down"></i>
                </div>

                <div className="col-12 col-lg-6 input-container">
                  <input
                    type="email"
                    id="subscribe-input"
                    name="cm-odhthb-odhthb"
                    className="js-cm-email-input"
                    aria-label="Subscribe for newsletters"
                    placeholder="Email address"
                    required
                  />
                  <button aria-label="Subscribe">Subscribe</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
