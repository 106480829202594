import {
    submitButtonHandler,
    tooShort,
    noCapitalChars,
    noIntegerChars,
    noSpecialChars,
    exists
} from './FieldUtils';

/**
 * Set the privacy field.
 * 
 * @param {object}  privacy
 * @param {object}  submit
 * 
 * @returns {void}
 */
export const setPrivacyField = (privacy, submit) => {
    privacy.addEventListener('click', () => {
        let valid = true;

        if (privacy.checked === false) {
            valid = privacy.checked;
        }

        submitButtonHandler('privacy', valid, submit);
    });
};

/**
 * Ensure that the description field is at a maximum of 10 words.
 * 
 * @param {object}  description
 * @param {string}  textDanger
 * @param {object}  submit
 * 
 * @returns {void}
 */
export const setDescriptionField = (description, textDanger, submit) => {
    description.addEventListener('keyup', () => {
        const length = description.value.trim().split(' ').length;
        const helper = description.nextElementSibling;
        let valid = true;

        if (length > 10) {
            helper.classList.add(textDanger);
            valid = false;
        } else {
            helper.classList.remove(textDanger);
        }

        submitButtonHandler('description', valid, submit);
    });
};

/**
 * Setup the password for validation.
 * 
 * @param {object}  passwordValidation
 * @param {object}  submit
 * 
 * @returns {void}
 */
export const setPasswordField = (passwordValidation, submit) => {
    const {
        password,
        errorList,
        selectors,
        dNone
    } = passwordValidation;
    const {
        pLength,
        pCapitalChars,
        pIntegerChars,
        pSpecialChars
    } = selectors;

    password.addEventListener('keyup', () => {
        const value = password.value;
        let valid = true;

        // Must be at least 8 characters long.
        if (tooShort(value)) {
            valid = false;
            togglePasswordError(true, errorList, pLength, dNone);
        } else {
            togglePasswordError(false, errorList, pLength, dNone);
        }

        // Must include a capital letter.
        if (noCapitalChars(value)) {
            valid = false;
            togglePasswordError(true, errorList, pCapitalChars, dNone);
        } else {
            togglePasswordError(false, errorList, pCapitalChars, dNone);
        }

        // Must include a number.
        if (noIntegerChars(value)) {
            valid = false;
            togglePasswordError(true, errorList, pIntegerChars, dNone);
        } else {
            togglePasswordError(false, errorList, pIntegerChars, dNone);
        }

        // Must include one special character.
        if (noSpecialChars(value)) {
            valid = false;
            togglePasswordError(true, errorList, pSpecialChars, dNone);
        } else {
            togglePasswordError(false, errorList, pSpecialChars, dNone);
        }

        // If by the time we get to this point of execution the valid variable
        // is false, we want to ensure the error list is displayed. If it is true,
        // this means the password passed all regex rules, therefore we can hide the
        // error list from the user.
        if (!valid) {
            errorList.classList.remove(dNone);
        } else {
            errorList.classList.add(dNone);
        }

        submitButtonHandler('password', valid, submit);
    });
};

/**
 * Toggle the individual password errors on and off. Not exported.
 * 
 * @param {boolean} show 
 * @param {object}  errorList
 * @param {string}  error
 * @param {string}  dNone
 * 
 * @returns {void}
 */
const togglePasswordError = (show, errorList, error, dNone) => {
    const item = errorList.querySelector(error);

    switch (show) {
        case true:
            item.classList.remove(dNone);

            break;

        case false:
            item.classList.add(dNone);

            break;
    }
};

/**
 * Determine the type of event we want the field to listen out for.
 * 
 * @param {object} html 
 * 
 * @returns {mixed}
 */
const getEventType = html => {
    const type = html.tagName;
    let event = '';

    if (type === 'SELECT') {
        event = 'change';
    } else if (type === 'INPUT') {
        event = 'keyup';
    } else {
        event = false;
    }

    return event;
};

/**
 * Bind the normal validation rules for the given field.
 * 
 * @param {object} field 
 * @param {string} eventType 
 * @param {string} name 
 * @param {object} submit 
 * 
 * @returns {void}
 */
const bindNormalValidation = (field, eventType, name, submit) => {
    submitButtonHandler(name, false, submit);

    field.addEventListener(eventType, () => {
        let valid = true;

        if (field.value.length === 0) {
            valid = false;
        }

        submitButtonHandler(name, valid, submit);
    });
};

/**
 * Use the window requiredFields variable to set the required
 * fields for the form.
 * 
 * @param {array}   fields
 * @param {string}  username_field
 * @param {object}  submit
 * 
 * @returns {void}
 */
export const setRequiredFields = (fields, username_field, submit) => {
    fields.forEach(field => {
        const html = document.querySelector(field);
        let name = '';
        let validationType = '';
        let eventType = '';

        if (exists([html])) {
            html.required = true;
            name = html.parentNode.dataset.name ? html.parentNode.dataset.name : null;
            validationType = html.parentNode.dataset.validation ? html.parentNode.dataset.validation : null;

            if (field === username_field) {
                const removeAttributes = ['onfocus', 'onblur'];
                html.type = 'email';

                html.value = '';
                removeAttributes.forEach(attr => {
                    html.removeAttribute(attr);
                });
            }

            if (validationType === 'normal') {
                eventType = getEventType(html);
                bindNormalValidation(html, eventType, name, submit);
            } else if (validationType !== 'normal' &&
                typeof validationType == 'string' &&
                validationType.length > 0
            ) {
                console.warn('Invalid validation type passed in form — FieldValidationHandlers.js');
            }

            name === 'email' && html.getAttribute('type') === 'text' ?
            html.setAttribute('type', 'email') :
                null
            ;
        }
    });
};