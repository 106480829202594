import React from "react";
import HamburgerNavigation from '../hamburger-navigation/HamburgerNavigation';

class LeftHandNavigationMobile extends HamburgerNavigation {

  constructor(props) {
      super(props);
      this.toggleState = 0;
  } 

  componentDidMount() {
    document.querySelectorAll('.lhn-button')[0].addEventListener('keypress', (e) => {
      this.openMainMenu(e)
    })
    
    document.querySelectorAll('.lhn-button')[0].addEventListener('mousedown', (e) => {
      this.openMainMenu(e)
    })      
  }

     openMainMenu = (e) => { 
                var mainMenu = document.querySelectorAll('.lhn.level1-list')[0]
                if (e.key == "Enter" || e.type == "mousedown") {
                    if (mainMenu.classList.contains('closed')) {
                        mainMenu.classList.add("open")
                        mainMenu.classList.remove("closed")
                     
                        document.querySelectorAll('.lhn-button')[0].classList.add('open')   
                    } else {
                        mainMenu.classList.add("closed")
                        mainMenu.classList.remove("open")
                        
                        document.querySelectorAll('.lhn-button')[0].classList.remove('open')  
                    }
                }                            
            }

  render() {
    return (     
        <div id="left-hand-navigation-mobile-container">
            <div id="left-hand-navigation-mobile-button-container" className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <a href={this.props.leftHandNavigationData.nav[0].pageURL}>{this.props.leftHandNavigationData.nav[0].pageName}</a>
                        <button className="lhn-button"></button>
                    </div>
                </div>
            </div>
            <nav id="left-hand-navigation-mobile">
                <ul className={"lhn level1-list" + " " + this.state.level1}>

                    {this.props.leftHandNavigationData.nav[0].pages.map((categories, index) =>
                    <li className="level1-item" key={index}>
                        <a href={categories.pageURL}>{categories.pageName}</a>
                        {categories.pages.length > 0 ?
                        <span>
                            <span className="menu-close closed" aria-label="Open / close sub menu" tabIndex="0" onClick={(e)=> this.closeMenu(e)} onKeyDown={(e) => this.closeMenu(e)}></span>
                            <span className="menu-open" aria-label="Open / close sub menu" tabIndex="0" onClick={(e)=> this.openMenu(e)} onKeyDown={(e) => this.openMenu(e)}></span>
                        </span>
                        : null
                        }

                        <ul className={"level2-list" + " " + this.state.level2}>
                            {categories.pages.map((level2Pages , index) =>
                            <li key={index} className="level2-item">
                                <a href={level2Pages.pageURL}>{level2Pages.pageName}</a>
                                {level2Pages.pages.length > 0 ?
                                <span>
                                    <span className="menu-close closed" aria-label="Open / close sub menu" tabIndex="0" onClick={(e)=> this.closeMenu(e)} onKeyDown={(e) => this.closeMenu(e)}></span>
                                    <span className="menu-open" aria-label="Open / close sub menu" tabIndex="0" onClick={(e)=> this.openMenu(e)} onKeyDown={(e) => this.openMenu(e)}></span>
                                </span>
                                : null
                                }
                                <ul className={"level3-list" + " " + this.state.level3}>
                                    {level2Pages.pages.map((level3Pages , index) =>
                                    <li key={index} className="level3-item"><a href={level3Pages.pageURL}>{level3Pages.pageName}</a></li>
                                    )}
                                </ul>
                            </li>
                            )}
                        </ul>
                    </li>


                    )}

                </ul>
            </nav>
        </div>      
    );
  }

}//End LeftHandNavigationMobile class

export default  LeftHandNavigationMobile;