import React from "react";


class PromoCard extends React.Component {

	constructor(props){
		super()
	}


    render() {
      return (
		<div id="promo-card">
		    <div className={"container-fluid" + " " + this.props.data.cardType }>
		      <a href={this.props.data.targetPageURL}>
		        <div className="row">
		            <div className="col-sm-12 col-lg-2 icon-container">
		                <div className="icon-container-mobile">
		                    <span style={{backgroundImage: `url(${this.props.data.icon})`}}></span>
		                </div>
		            </div>
		            <div className="col-sm-12 col-lg-5 text-container ">
		                <h2>{this.props.data.heading}</h2>
		                <h3>{this.props.data.subheading}</h3>
		                <div>
		                <p>{this.props.data.description}</p>
		                </div>
		            </div>
		            <div className="col-sm-12 col-lg-5 image-container">
		                <div className="promo-card-image" style={{backgroundImage: `url(${this.props.data.image})`}}></div>
		            </div>
		        </div>
		      </a> 
		    </div>
		</div>
    );
  }
}

export default PromoCard;
